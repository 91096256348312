import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'

import { PageLayout, ProductList, PageBreadcrumb } from '../../components'
import { l } from '../../lib/locale'
import { context } from '../../store'

const Muuto2023DiningActionTemplate = ({ data, pageContext }) => {
  const { store } = useContext(context)
  const { lang, page } = pageContext

  let items = null
  if (store && store.app && store.app.cartrules) {
    const rule = store.app.cartrules.find(i => i.display_code && i.display_code.toUpperCase() === "MUUTO2023DINING")
    items = data.allShopProduct.edges.filter(({ node }) => {
      return (rule && rule.items && rule.items.includes(+node.id_product))
    }).map(i => i.node)
  }

  const navigation = {
    page: 0
  }
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    navigation.page = qs.page ? +qs.page : 0
  }

  const header = (
    <p dangerouslySetInnerHTML={{ __html: l('action-muuto2023dining-intro', lang) }} />
  )

  return (
    <PageLayout lang={lang} switcher={page}>
      <div className="product-list-4 product-list-breadcrumbs">
        <PageBreadcrumb
          lang={lang}
          current={page}
          items={[ 'homepage' ]}
          />
        <ProductList
          title={page[`title_${lang.suffix}`]}
          header={header}
          products={items}
          attributes={data.allShopAttribute.edges.map(i => i.node)}
          attribute_groups={data.allShopAttributeGroup.edges.map(i => i.node)}
          lang={lang}
          navigation={navigation}
          keyPrefix="muuto2023dining" />
      </div>
    </PageLayout>
  )
}

export default Muuto2023DiningActionTemplate

export const query = graphql`
  query Muuto2023DiningActionTemplateQuery {
    allShopProduct {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }
    allShopAttributeGroup {
      edges {
        node {
          group_type
          id_attribute_group
          is_color_group
          name_en
          name_nl
          position
          public_name_en
          public_name_nl
        }
      }
    }
    allShopAttribute {
      edges {
        node {
          id_attribute
          id_attribute_group
          color
          name_en
          name_nl
          position
        }
      }
    }
  }
`
